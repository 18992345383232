<script>
import { mapGetters, mapMutations } from 'vuex';
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	props: ['client'],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},
	data() {
		return {
			indexSelected: 0,
			formData: {
                // Standalone Rating
                scale: 'Long Scale BBVA',
                long_rating_value: '8.3',
                total_rating_score: '8.75',
                ffss_year: '2022-06-30',
			},
		}
	},

	computed: {
		...mapGetters({
			'ratingSelected': 'arceRatings/selected'
		})
	},

	
	methods: {
		calcFill() {
			let fields = Object.keys(this.form)
			if(this.form.multipleGeografia == 'NO') {
				let index = fields.indexOf('comentariosMultipleGeografia')
				fields.splice(index, 1)
			}
			if(this.form.multipleActividadEconomica == 'NO') {
				let index = fields.indexOf('comentariosMultipleActividadEconomica')
				fields.splice(index, 1)
			}
			let points = 100 / fields.length

			let fill = 0

			fields.forEach(f => {
				if(this.form[f]) {
					fill += points
				}
			})

			return Math.round(fill)
		},
		save() {
			let vm = this
			let payload =  {
				key: 'standalone_rating',
				form: {...this.formData},
				data: {},
			}
			vm.updateAnalysis(payload)
			this.hideModal('#formStandaloneRatingModal')
		},
		showModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).remove()
				this.showModal(id)
			} else {
				$(id).appendTo("body").modal('show')

			}
		},
		hideModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).modal('hide')
			}
		},
		closeModal() {
			this.updateFormData()
		},
		updateFormData() {
			let formData = JSON.parse(JSON.stringify(this.ratingSelected.analysis.standalone_rating.form))
			this.$set(this, 'formData', formData)
		},
		...mapMutations({
			updateAnalysis: 'arceRatings/updateAnalysis'
		}) 
		
	},
	mounted() {
		this.updateFormData()
		$('#formStandaloneRatingModal').on('hide.bs.modal', this.closeModal)
	},

	beforeDestroy() {
		$('#formStandaloneRatingModal').off('hide.bs.modal', this.closeModal)
	}
	
}
</script>
<template>
	<div style="">
		<div class="text-right py-2">
            <button class="s-btn s-btn--primary" @click="showModal('#formStandaloneRatingModal')">
                Edit
            </button>
        </div>  
		<div  v-if="formData" class="py-3">
			
			
			<div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_sr_n_1" aria-expanded="true" aria-controls="c_sr_n_1">
				<div class="d-flex">
					<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Standalone Rating</span></h6>
				</div>
			</div>
			<div id="c_sr_n_1" class="collapse show" aria-labelledby="c_sr_n_1">
				<div class="d-flex flex-wrap">
					<div class="col-4 py-3">
						<p class="m-0">Long Scale BBVA</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.standalone_rating.form.scale">
					</div>
					<div class="col-4 py-3">
						<p class="m-0">Total rating score</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.standalone_rating.form.total_rating_score">
					</div>
				</div>
                <div class="d-flex flex-wrap">
					<div class="col-4 py-3">
						<p class="m-0">FFSS Year</p>
						<input class="form-control form-control--disabled" disabled type="text" v-model="ratingSelected.analysis.standalone_rating.form.ffss_year">
					</div>
                </div>
			</div>
			
		</div>
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<div class="modal s-modal fade" style="font-size: 0.8em" id="formStandaloneRatingModal" tabindex="-1" role="dialog" aria-labelledby="formStandaloneRatingModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl " role="document">
				<div class="modal-content">
				<div class="modal-header p-0">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div  v-if="formData" class="py-3">
                        <div class="s-collapse__header collapsed" data-toggle="collapse" data-target="#c_sr_m_1" aria-expanded="true" aria-controls="c_sr_m_1">
                            <div class="d-flex">
                                <h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>Standalone Rating</span></h6>
                            </div>
                        </div>
                        <div id="c_sr_m_1" class="collapse show" aria-labelledby="c_sr_m_1">
                            <div class="d-flex flex-wrap">
                                <div class="col-4 py-3">
                                    <p class="m-0">Long Scale BBVA</p>
                                    <input class="form-control" type="text" v-model="formData.scale">
                                </div>
                                <div class="col-4 py-3">
                                    <p class="m-0">Total rating score</p>
                                    <input class="form-control" type="text" v-model="formData.total_rating_score">
                                </div>
                            </div>
                            <div class="d-flex flex-wrap">
                                <div class="col-4 py-3">
                                    <p class="m-0">FFSS Year</p>
                                    <input class="form-control" type="text" v-model="formData.ffss_year">
                                </div>
                            </div>
                        </div>
                        
                    </div>
				</div>
				<div class="modal-footer">
					<button type="button" class="s-btn s-btn--outline" data-dismiss="modal">Cancelar</button>
					<button type="button" class="s-btn s-btn--primary" data-dismiss="modal" @click="save">Guardar</button>
				</div>
			</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.charts{
	max-height: 125px !important;
	min-height: 125px !important;
	height: 125px !important;
}
</style>